import React, { useState } from "react";
import Landing from "./pages/Landing.js";
import Resume from "./pages/Resume.js";
import Arrow from "./components/Arrow";
import styled from "styled-components";

function App() {
  const [currentPage, switchPage] = useState("home");

  const goToPage = (page) => {
    switchPage(page);
  };

  return (
    <AppWrapper>
      {currentPage === "home" ? <Arrow></Arrow> : <> </>}
      <Landing currentPage={currentPage} goToPage={goToPage} />
      {currentPage === "home" ? <Resume /> : <></>}
    </AppWrapper>
  );
}

export default App;

const AppWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

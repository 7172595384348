import React, { useState } from "react";

import styled, { keyframes } from "styled-components";

function App() {
  return (
    <TextBox>
      <Title>Bridget's Blog</Title>
    </TextBox>
  );
}

export default App;

const fadeIn = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  background-color: #a4c9bc;
  min-height: 100vh;
  width: 100vw;
`;

const TextBox = styled.div`
  animation: ${fadeIn} 1s;
  margin: 5%;
  position: relative;
  z-index: 1;

  /* crazy mode  */

  /* backdrop-filter: hue-rotate(180deg) blur(20px);
  padding: 20px; */
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 550px));
  grid-gap: 1rem;
  font-family: Lato, sans-serif;
  color: hsl(158.9, 25.5%, 5%);

  max-height: 559px;

  @media only screen and (max-width: 1239px) {
    max-height: unset;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => (props.right ? "22%" : "")};
  margin-top: ${(props) => (props.right ? "37px" : "")};

  @media only screen and (max-width: 1239px) {
    margin-left: 0;
    margin-top: 0;
  }
`;

const Title = styled.h2`
  position: relative;
  z-index: 1;
  margin: 0;
  font-weight: 500;
  font-style: normal;
  font-size: 36px;
  line-height: 89px;
  /* or 247% */
  text-decoration-line: underline;
  color: #eff2f1;
`;

const SubTitle = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  font-size: 20px;
  line-height: 26px;
  margin: 0;

  @media only screen and (max-width: 400px) {
    font-size: 16px;
  }
`;

const Text = styled.p`
  line-height: ${(props) => (props.date ? "11px" : "20px")};
  margin: 0;
  margin-bottom: ${(props) => (props.skill ? "20px" : "0")};
  font-size: ${(props) => (props.date ? "12px" : "")};
`;

const JobLink = styled.a`
  margin: 0;
  font-weight: bold;
  text-decoration-line: underline;
  color: #1e4f9b;
  margin-right: 4px;
`;
